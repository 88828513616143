/**
 * Originially taken from add-view-file.blade.php
 */

type YYYY_MM_DD = `${number}-${number}-${number}`

// Setup client-side validation for addClient form (for email). Must be done before addMainFormClient()
const formatDateToInput = (dateInYYYYMMDD: YYYY_MM_DD) => {
    if (!dateInYYYYMMDD) {
        return '';
    }
    const [year, month, day] = dateInYYYYMMDD.split('-');
    return `${month}/${day}/${year}`;
}

export const validateForm = (formSelector: JQuery.Selector) => {
    $(formSelector).validate({
        messages: {
            close_date: {
                min: (message: YYYY_MM_DD) => {
                    return `Please enter a date on or after ${formatDateToInput(message)}`;
                },
                max: (message: YYYY_MM_DD) => {
                    return `Please enter a date on or before ${formatDateToInput(message)}`;
                }
            }
        }
    });
}
